<template>
    <section class="advanced-form-elements new-updated-design">
        <vue-snotify></vue-snotify>
        <system_app_settings></system_app_settings>
         <br>
        <div class="row">
            <div class="col-md-12 grid-margin">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('notifications') }}</h4>
                    </div>
                    <form @submit.stop.prevent="onSubmit">
                        <div class="card-body new-card-body">
                           <div class="row">
                                <div class="col-md-5 mt-2" style="text-align: left;">
                                    {{ $t('send-mail-notification-every-time-a-spot-is-added') }}
                                </div>
                                <div class="col-md-2 mt-2">
                                    <toggle-button @change="updateEmailSettings()" v-model="form.enable_mail_notification" :sync=true class="switchColor"/>
                                </div> 
                                <div class="col-md-5" style="text-align: left;">
                                    <b-form-input name="email" v-model="form.emails" :placeholder="$t('email-address')"></b-form-input>
                                    {{ $t('separate-addresses-by-commas-if-more-than-1') }}
                                </div> 
                           </div>
                           <div class="row">
                                <div class="col-md-5 mt-2" style="text-align: left;">
                                    {{ $t('Include:') }}
                                    <div class="ml-2">
                                        <b-form-checkbox v-model="form.include_spot_id" checked-value=true unchecked-value=false>  {{ $t('spot-id') }}</b-form-checkbox>
                                        <b-form-checkbox v-model="form.include_category" checked-value=true unchecked-value=false>  {{ $t('category') }}</b-form-checkbox>
                                        <b-form-checkbox v-model="form.include_spot_type" checked-value=true unchecked-value=false>  {{ $t('spot-type') }}</b-form-checkbox>
                                        <b-form-checkbox v-model="form.include_location" checked-value=true unchecked-value=false>  {{ $t('location') }}</b-form-checkbox>
                                        <b-form-checkbox v-model="form.include_spotter" checked-value=true unchecked-value=false>  {{ $t('spotter') }}</b-form-checkbox>
                                        <b-form-checkbox v-model="form.include_spot_details" checked-value=true unchecked-value=false>  {{ $t('details') }}</b-form-checkbox>
                                    </div>
                                    
                                </div>
                           </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                                <b-button type="submit" variant="success" class="mr-4 mb-3 float-right"
                                          :disabled="isDisabled">{{ $t('submit-btn') }}
                                </b-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </section>

</template>

<script lang="js">
import API from '@/api'
import Snotify, {SnotifyPosition} from 'vue-snotify'
import Vue from "vue";
import system_app_settings from './system_app_settings.vue';

const options = {
    toast: {
        timeout: 2000,
        position: SnotifyPosition.rightTop,
    }
}
Vue.use(Snotify, options)
export default {
    name: 'Billing',
    components: {
        system_app_settings
    },
    data() {
        return {
            form: {
                enable_mail_notification: false,
                emails:'',
                include_spotter:'',
                include_spot_id:'',
                include_category:'',
                include_spot_type:'',
                include_location:'',
                include_spot_details:''
            },
            notificationSettings: [],
            // emails:[],
            client_id: JSON.parse(localStorage.getItem('client_id')),
            isDisabled: false,
        }
    },

    methods: {
        onSubmit() {
            const fd = new FormData();
                fd.append('enable_mail_notification', this.form.enable_mail_notification);
                fd.append('emails', this.form.emails.split(','));
                fd.append('include_spotter', this.form.include_spotter);
                fd.append('include_spot_id', this.form.include_spot_id);
                fd.append('include_category', this.form.include_category);
                fd.append('include_spot_type', this.form.include_spot_type);
                fd.append('include_location', this.form.include_location);
                fd.append('include_spot_details', this.form.include_spot_details);
                fd.append('client_id', this.client_id);
            API.updateNotificationSettings(
                fd,
                data => {
                    this.$snotify.success(data.message, {
                        timeout: 1000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true
                      })
                },
                err => {
                    console.log(err);
                }
            );
        },
        getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                    data,
                    data => {
                        this.notificationSettings = data.data.notification_settings;
                        console.log('this.notificationSettings :', this.notificationSettings);
                        if(this.notificationSettings) {
                            this.form.enable_mail_notification = JSON.parse(this.notificationSettings.enable_mail_notification.toLowerCase());
                            this.form.emails = this.notificationSettings.emails;
                            this.form.include_spotter = this.notificationSettings.include_spotter;
                            this.form.include_spot_id = this.notificationSettings.include_spot_id;
                            this.form.include_category = this.notificationSettings.include_category;
                            this.form.include_spot_type = this.notificationSettings.include_spot_type;
                            this.form.include_location = this.notificationSettings.include_location;
                            this.form.include_spot_details = this.notificationSettings.include_spot_details;
                        }
                        // this.form.training_complete = (data.data.training_complete === 1) ? true : false;
                        // this.form.enable_acoustic = (data.data.enable_acoustic === 1) ? true : false;
                        // this.url = data.data.image;
                    },
                    err => {
                    }
                )
            },
    },
    beforeMount() {
    },
    created() {
        this.getAppSettings();
    },
    mounted() {
    },
}
</script>
<style lang="scss" scoped>
.card-body {
    min-height: 200px !important;
    text-align: center !important;
    h3 {
        color: red;
        font-size: 20px;
    }
}
</style>
