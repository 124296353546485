
<template>
<div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navbar">
          <ul class="navbar-nav">
            <!-- <li class="nav-item me-3" v-if="permissionExist('system_settings')">
                <router-link class="navbar-brand d-flex align-items-center nav-link" to="/app/system/settings">
                    <span class="menu-title">{{ $t('System Settings') }}</span>
                </router-link>
            </li> -->
            <li class="nav-item me-3" v-if="permissionExist('share_settings')" v-on:click="collapseAll">
                <router-link class="navbar-brand d-flex align-items-center nav-link" to="/app/share/settings">
                    <!-- <i class="ti-user menu-icon me-1"></i> -->
                    <span class="menu-title">{{ $t('share-settings') }}</span>
                </router-link>
            </li>
            <li class="nav-item me-3" v-if="permissionExist('map_settings')" v-on:click="collapseAll">
                <router-link class="navbar-brand d-flex align-items-center nav-link" to="/app/map/settings">
                    <!-- <i class="ti-user menu-icon me-1"></i> -->
                    <span class="menu-title">{{ $t('map-settings') }}</span>
                </router-link>
            </li>
            <li class="nav-item me-3" v-if="permissionExist('spotter_settings')" v-on:click="collapseAll">
                <router-link class="navbar-brand d-flex align-items-center nav-link" to="/app/spotter/settings">
                    <!-- <i class="ti-user menu-icon me-1"></i> -->
                    <span class="menu-title">{{ $t('spotter-settings') }}</span>
                </router-link>
            </li>
            <li class="nav-item me-3" v-if="permissionExist('brand_settings')" v-on:click="collapseAll">
                <router-link class="navbar-brand d-flex align-items-center nav-link" to="/app/branding/settings">
                    <!-- <i class="ti-user menu-icon me-1"></i> -->
                    <span class="menu-title">{{ $t('branding') }}</span>
                </router-link>
            </li>
            <li class="nav-item me-3" v-if="permissionExist('field_guide')" v-on:click="collapseAll">
                <router-link class="navbar-brand d-flex align-items-center nav-link" to="/app/field_guide">
                    <!-- <i class="ti-user menu-icon me-1"></i> -->
                    <span class="menu-title">{{ $t('field-guide') }}</span>
                </router-link>
            </li>
            <!-- <li class="nav-item me-3" v-if="permissionExist('field_guide')" v-on:click="collapseAll">
                <router-link class="navbar-brand d-flex align-items-center nav-link" to="/app/billing">
                   <i class="ti-user menu-icon me-1"></i> 
                    <span class="menu-title">{{ $t('billing') }}</span>
                </router-link>
            </li> -->
            <li class="nav-item me-3" v-if="permissionExist('field_guide')" v-on:click="collapseAll">
                <router-link class="navbar-brand d-flex align-items-center nav-link" to="/app/notification">
                    <!-- <i class="ti-user menu-icon me-1"></i> -->
                    <span class="menu-title">{{ $t('notifications') }}</span>
                </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
</div>
</template>

<script lang="js">
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import Vue from "vue";
    import {validationMixin} from "vuelidate";
    import $ from 'jquery';
    import {required, minLength, email, requiredIf, sameAs, between} from "vuelidate/lib/validators";
    const options = {
        toast: {
            timeout: 2000,
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        components:{
        },
        data() {
            return {
                version_code: '',
                version_name: '',
                collapses: [{show: false}, {show: false}, {show: false}],
                getPermissionsData: [],
            };
        },
        methods: {
            getPermissions() {
                this.getPermissionsData = JSON.parse(localStorage.getItem('userPermissions'));
            },
            getClient() {
                const data = {
                    id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.get_client(
                    data,
                    data => {
                        if(data.version) {
                            this.version_name = data.version.version_name;
                            this.version_code = data.version.version_code;
                        }
                    },
                    err => {})
            },
            permissionExist(param) {
                var check = this.getPermissionsData.includes(param);
                if (check) {
                    return true;
                } else {
                    return false;
                }
            },
            collapseAll() {
                var exp_elm = document.getElementsByClassName("show");
                if (exp_elm.length > 0) {
                    var elm_id = exp_elm[0].id;
                    this.$root.$emit("bv::toggle::collapse", elm_id);
                }
            },
            // activeParent() {
            //     document.querySelector(".nav-link.active").classList.parent.a.addClass("sub-nav-item");
            // }
        },
        mounted() {
            const body = document.querySelector("body");
            // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
            document.querySelectorAll(".navbar .nav-item").forEach(function (el) {
                el.addEventListener("mouseover", function () {
                    if (body.classList.contains("sidebar-icon-only")) {
                        el.classList.add("hover-open");
                    }
                });
                el.addEventListener("mouseout", function () {
                    if (body.classList.contains("sidebar-icon-only")) {
                        el.classList.remove("hover-open");
                    }
                });
            });


        }, beforeMount() {
            this.getPermissions();
            var client_id = JSON.parse(localStorage.getItem('client_id'));
            if(client_id !== null) {
                this.getClient();
            }
        },
        watch: {
            $route() {
                document.querySelector("#sidebar").classList.toggle("active");
            }
        }
    };

</script>
<style scoped>
    .document-editor {
        border: 1px solid var(--ck-color-base-border);
        border-radius: var(--ck-border-radius);

        /* Set vertical boundaries for the document editor. */
        max-height: 700px;

        /* This element is a flex container for easier rendering. */
        display: flex;
        flex-flow: column nowrap;
    }

    .document-editor__toolbar {
        /* Make sure the toolbar container is always above the editable. */
        z-index: 1;

        /* Create the illusion of the toolbar floating over the editable. */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .2);

        /* Use the CKEditor CSS variables to keep the UI consistent. */
        border-bottom: 1px solid var(--ck-color-toolbar-border);
    }

    /* Adjust the look of the toolbar inside the container. */
    .document-editor__toolbar .ck-toolbar {
        border: 0;
        border-radius: 0;
    }

    /* Make the editable container look like the inside of a native word processor application. */
    .document-editor__editable-container {
        padding: calc(2 * var(--ck-spacing-large));
        background: var(--ck-color-base-foreground);

        /* Make it possible to scroll the "page" of the edited content. */
        overflow-y: scroll;
    }

    .document-editor__editable-container .ck-editor__editable {
        /* Set the dimensions of the "page". */
        width: 15.8cm;
        min-height: 21cm;

        /* Keep the "page" off the boundaries of the container. */
        padding: 1cm 2cm 2cm;

        border: 1px hsl(0, 0%, 82.7%) solid;
        border-radius: var(--ck-border-radius);
        background: white;

        /* The "page" should cast a slight shadow (3D illusion). */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .1);

        /* Center the "page". */
        margin: 0 auto;
    }

    /* Set the default font for the "page" of the content. */
    .document-editor .ck-content,
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    /* Adjust the headings dropdown to host some larger heading styles. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        line-height: calc(1.7 * var(--ck-line-height-base) * var(--ck-font-size-base));
        min-width: 6em;
    }

    /* Scale down all heading previews because they are way too big to be presented in the UI.
    Preserve the relative scale, though. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
        transform: scale(0.8);
        transform-origin: left;
    }

    /* Set the styles for "Heading 1". */
    .document-editor .ck-content h2,
    .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
        font-size: 2.18em;
        font-weight: normal;
    }

    .document-editor .ck-content h2 {
        line-height: 1.37em;
        padding-top: .342em;
        margin-bottom: .142em;
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3,
    .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
        font-size: 1.75em;
        font-weight: normal;
        color: hsl(203, 100%, 50%);
    }

    .document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
        color: var(--ck-color-list-button-on-text);
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3 {
        line-height: 1.86em;
        padding-top: .171em;
        margin-bottom: .357em;
    }

    /* Set the styles for "Heading 3". */
    .document-editor .ck-content h4,
    .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
        font-size: 1.31em;
        font-weight: bold;
    }

    .document-editor .ck-content h4 {
        line-height: 1.24em;
        padding-top: .286em;
        margin-bottom: .952em;
    }

    /* Set the styles for "Paragraph". */
    .document-editor .ck-content p {
        font-size: 1em;
        line-height: 1.63em;
        padding-top: .5em;
        margin-bottom: 1.13em;
    }

    /* Make the block quoted text serif with some additional spacing. */
    .document-editor .ck-content blockquote {
        font-family: Georgia, serif;
        margin-left: calc(2 * var(--ck-spacing-large));
        margin-right: calc(2 * var(--ck-spacing-large));
    }

    .ck.ck-editor__editable_inline {
        border: 1px solid #e3e3e3;
        overflow: auto;
        padding: 0 var(--ck-spacing-standard);
    }

.btn-2 {
  display: flex;
  align-items: center;
  --background: #2b3044;
  --background-hover: #1e2235;
  background: none;
  border: 1px solid lighten(gray, 24%);
  height: 48px;
  padding: 0 24px 0 16px;
  letter-spacing: .25px;
  border-radius: 18px;
  cursor: pointer;
}

.btn-delete {
  font-size: 12px;
  color: red;
}

.button-62 {
  background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.btn-2:not([disabled]):focus {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.btn-2:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}
.tab .nav-tabs{
    border-bottom:0 none;
    margin-top: 20px;
    /* width: 80% ; */
}
.navbar{
    background-color: #9a5379;
    color: #9a5379;
}
.tab .nav-tabs li a {
    font-size:14px;
    color:#fff;
    margin-right:0;
    padding:10px 45px;
    border-radius:0;
    overflow: hidden;
    background:#9a5379;
    text-transform:uppercase;
    box-shadow: 8px 12px 25px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
}
.tab .nav-tabs li:first-child a{
    border-top-left-radius:8px;
}
.tab .nav-tabs li:last-child a{
    border-top-right-radius:8px;
}
.tab .nav-tabs li a:hover,
.tab .nav-tabs li.active a:hover,
.tab .nav-tabs li.active a{
    background:#545f60;
    color:#fff;
    margin-top: -19px;
    padding:20px 45px;
    border:1px solid #545f60;
}
.tab .tab-content{
    color:#545f60;
    padding: 25px 20px;
    /* background:#545f60; */
    border-radius: 0 0 8px 8px;
    box-shadow: 8px 12px 25px 2px rgba(0, 0, 0, 0.4);
}
/* .tab .tab-content .h3{
    color:rgb(221, 19, 19);
    padding: 25px 20px;
    background:#fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 8px 12px 25px 2px rgba(0, 0, 0, 0.4);

} */
.my_nav{
    list-style-type: none
}
@media (max-width: 1150px) {
    .card .new-card-body {
        padding: 1.25rem 1.437rem !important;
    }
}
@media only screen and (max-width: 650px){
    .tab .nav-tabs li{ width:100%; }
    .tab .nav-tabs li a{
        margin:0;
        padding:20px 45px;
        box-shadow: -2px -1px 25px 2px rgba(0, 0, 0, 0.4);
    }
    .tab .nav-tabs li:first-child a,
    .tab .nav-tabs li:last-child a{
        border-radius: 0;
    }
    .tab .nav-tabs li a:hover,
    .tab .nav-tabs li.active a:hover,
    .tab .nav-tabs li.active a{
        margin:0;
    }
}
</style>
